$('#gallery-main-image').magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});

$('#gallery-main-image').slick({
  adaptiveHeight: false,
  arrows: false,
  asNavFor: '#gallery-thumbs',
  fade: true,
  lazyLoad: 'ondemand'
});
$('#gallery-thumbs').slick({
  arrows: false,
  asNavFor: '#gallery-main-image',
  focusOnSelect: true,
  infinite: false,
  slidesToShow: 5,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        dots: true,
        slidesToScroll: 3,
        slidesToShow: 3
      }
    }
  ]
});


/*///////// Wheel Gallery //////////*/
$('#wheel-gallery-main-image .item > a').magnificPopup({
  type: 'image',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});
$('#wheel-gallery-main-image').slick({
  arrows: true,
  asNavFor: '',
  fade: true,
  lazyLoad: 'ondemand',
  adaptiveHeight: true,
});
$('#wheel-gallery-thumbs').slick({
  arrows: false,
  asNavFor: '#wheel-gallery-main-image',
  focusOnSelect: true,
  infinite: false,
  slidesToShow: 5,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        dots: true,
        slidesToScroll: 3,
        slidesToShow: 3
      }
    }
  ]
});

$('#wheel-gallery-thumbs img').click(function(){
  let vehicleTitle = $(this).data('title');
  let vehicleDetails = $(this).data('details');
  let galleryLink = $(this).data('link');
  $('.vehicle-title').text(vehicleTitle);
  $('.vehicle-details').text(vehicleDetails);
  $('.vehicle-gallery-button').attr('href',galleryLink);
});
